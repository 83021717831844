<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid, dirty }">
      <v-form>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="Title" rules="required|min:2|max:255">
              <v-text-field
                v-model="itineraryItemLocal.title"
                :disabled="loading"
                label="Title"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Type">
              <v-select
                v-model="itineraryItemLocal.itinerary_item_types_id"
                :disabled="loading"
                :items="lists.itinerary_item_types"
                :menu-props="{ maxHeight: '400' }"
                label="Type"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" v-if="itineraryItemLocal.itinerary_item_types_id == 1">
            <ValidationProvider name="Date" rules="date">
              <DateInput
                v-model="itineraryItemLocal.date"
                label="Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" v-if="itineraryItemLocal.itinerary_item_types_id == 1">
            <ValidationProvider name="Start Time">
              <TimeInput
                v-model="itineraryItemLocal.start_time"
                :disabled="loading"
                label="Start time"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" v-if="itineraryItemLocal.itinerary_item_types_id == 1">
            <ValidationProvider name="End Time">
              <TimeInput
                v-model="itineraryItemLocal.end_time"
                :disabled="loading"
                label="End time"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" v-if="itineraryItemLocal.itinerary_item_types_id == 1">
            <ValidationProvider name="Time TBA">
              <v-switch v-model="itineraryItemLocal.time_tba" label="Time TBA"></v-switch>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Completed">
              <v-textarea
                v-model="itineraryItemLocal.note"
                label="Notes"
                hint="Printed notes goes here"
                rows="5"
                filled
                auto-grow
              ></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
        <v-btn
          @click="deleteAction"
          :disabled="loading || itinerary_item_id == 'new'"
          outlined
          color="primary"
          class="ma-2"
          >Delete</v-btn
        >
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, min, max } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import { date } from '@/utils/helpers';
import DateInput from '@/views/DateInput';
import TimeInput from '@/views/TimeInput';

extend('required', required);
extend('min', min);
extend('max', max);
extend('date', date);

export default {
  name: 'SubgroupItineraryDetailForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    DateInput,
    TimeInput
  },
  props: ['subgroup_id', 'itinerary_item_id', 'isvisible'],
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('itineraryItem', ['loading', 'itineraryItem', 'error', 'success', 'meta', 'lists']),
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        itinerary_item_types: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save.
     */
    itineraryItemLocal: function () {
      return loadDash.cloneDeep(this.itineraryItem);
    }
  },
  methods: {
    /**
     * Load the itineraryItem
     */
    loadAction: function () {
      store.dispatch('itineraryItem/get', this.itinerary_item_id);
    },
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result && this.subgroup_id > 0) {
        this.itineraryItemLocal.subgroup_id = this.subgroup_id;
        await store.dispatch('itineraryItem/save', {
          id: this.itineraryItemLocal.id,
          itineraryItem: this.itineraryItemLocal
        });
      }
      this.$emit('closed');
    },
    /**
     * Delete action for the form
     */
    deleteAction() {
      store.dispatch('itineraryItem/deleteItinerary', this.itineraryItemLocal.id);
      this.$emit('closed');
    }
  },
  mounted: async function () {
    this.loadAction();
  },
  watch: {
    isvisible: function () {
      if (this.isvisible) {
        this.loadAction();
      }
    },
    success: function () {
      this.$emit('closed');
    }
  },
  data: () => ({
    data: null
  })
};
</script>
