<template>
  <v-container>
    <v-form>
      <v-row>
        <v-col cols="6" md="2">
          <v-text-field
            v-model="subgroupLocal.itinierary_version"
            :disabled="loading"
            label="Itinerary Version"
            @change="updateItinierary"
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="4">
          <v-switch
            v-model="subgroupLocal.itinierary_sent"
            label="Sent to client"
            @change="updateItinierary"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-end">
          <AutoComplete
            v-model="itinerary_item_template"
            search-service="itineraryItemTemplate"
            :disabled="loading"
            label="Code"
          />
          <v-btn color="primary" class="ma-2" :disabled="!addButtonEnabled" @click="addItineraryTemplate()"
            ><v-icon dark>mdi-plus</v-icon></v-btn
          >
          <v-btn color="primary" class="ma-2" @click="copyItinerary()">Copy</v-btn>
          <v-btn color="primary" class="ma-2" @click="addItineraryItem()">New Item</v-btn>
          <v-btn color="primary" class="ma-2" :disabled="!addWeekendButtonEnabled" @click="addWeekends()">
            <v-icon dark>mdi-calendar-range</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      :headers="headers"
      :items="itineraryItems"
      :options.sync="options"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      :items-per-page="items_per_page"
      v-model="selectedRows"
      hide-default-footer
      show-select
    >
      <template v-slot:item.time_tba="{ item }">
        {{ formatYesNo(item.time_tba) }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template v-slot:item.start_time="{ item }">
        {{ formatTime(item.start_time) }}
      </template>
      <template v-slot:item.end_time="{ item }">
        {{ formatTime(item.end_time) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click.stop="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-btn color="primary mt-5" v-if="selectedRows.length" @click="deleteSelected()">Delete</v-btn>
    <Paginate :meta="meta" v-on:paginate="paginateAction" />
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
    <v-dialog
      max-width="600"
      width="auto"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="showItineraryItemManageDialog"
    >
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Itinerary Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showItineraryItemManageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <SubgroupItineraryItemDetailForm
            :subgroup_id="subgroup.id"
            :itinerary_item_id="itinerary_item_id"
            :isvisible="showItineraryItemManageDialog"
            @closed="onItineraryItemMangageDialogClosed"
          />
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      max-width="600"
      width="auto"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="showItineraryItemCopyDialog"
    >
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Itinerary Copy</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showItineraryItemCopyDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <SubgroupItineraryItemCopyForm
            :subgroup_id="subgroup.id"
            :isvisible="showItineraryItemCopyDialog"
            @closed="onItineraryItemCopyDialogClosed"
          />
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>
<style scoped>
.small {
  max-width: 120px;
}
</style>
<script>
import { dateMixin } from '@/mixins/dateMixin';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import AutoComplete from '@/components/AutoComplete';
import SubgroupItineraryItemDetailForm from '@/views/SubgroupItineraryItemDetailForm';
import SubgroupItineraryItemCopyForm from '@/views/SubgroupItineraryItemCopyForm';
import Paginate from '@/views/Paginate';

export default {
  name: 'SubroupItineraryItemList',
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store
      .dispatch('itineraryItem/list', {
        subgroup_id: to.params.id,
        page: currentPage,
        per_page: 100,
        sort_by: 'date',
        sort_desc: false
      })
      .then(() => {
        to.params.page = currentPage;
        next();
      });
  },
  components: {
    AutoComplete,
    SubgroupItineraryItemCopyForm,
    SubgroupItineraryItemDetailForm,
    ConfirmDialog,
    Paginate
  },
  mixins: [dateMixin],
  computed: {
    ...mapGetters('itineraryItem', ['loading', 'error', 'itineraryItems', 'meta', 'links']),
    ...mapGetters('subgroup', ['subgroup']),
    subgroupLocal: function () {
      const subgroup = {
        id: this.subgroup.id,
        itinierary_version: this.subgroup.itinierary_version,
        itinierary_sent: this.subgroup.itinierary_sent
      };
      return subgroup;
    }
  },
  methods: {
    load() {
      if (this.subgroup.id) {
        store.dispatch('itineraryItem/list', {
          subgroup_id: this.subgroup.id,
          page: this.options.page,
          per_page: this.items_per_page,
          sort_by: this.options.sortBy[0] ?? 'date',
          sort_desc: this.options.sortDesc[0] ?? false
        });
      }
    },
    async addItineraryTemplate() {
      if (this.itinerary_item_template) {
        const value = parseInt(this.itinerary_item_template.id, 10);
        if (value > 0 && this.subgroup.id > 0) {
          await store.dispatch('itineraryItem/createItineraryItemFromTemplate', {
            subgroup_id: this.subgroup.id,
            itinerary_item_templates_id: value
          });
          this.itinerary_item_template = null;
          this.load();
        }
      }
    },
    copyItinerary() {
      this.showItineraryItemCopyDialog = true;
    },
    addItineraryItem() {
      this.itinerary_item_id = 'new';
      this.showItineraryItemManageDialog = true;
    },
    async addWeekends() {
      await store.dispatch('itineraryItem/generateWeekendsForSubgroup', {
        subgroup_id: this.subgroupLocal.id
      });
      this.load();
    },
    editItem(item) {
      this.itinerary_item_id = item.id;
      this.showItineraryItemManageDialog = true;
    },
    updateItinierary() {
      store.dispatch('subgroup/save', {
        id: this.subgroupLocal.id,
        subgroup: this.subgroupLocal
      });
    },
    deleteItem(item) {
      this.itemsToDelete.splice(0, this.itemsToDelete.length);
      this.itemsToDelete[0] = item.id;
      this.showDeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      for (const id of this.itemsToDelete) {
        await store.dispatch('itineraryItem/delete', id);
        this.selectedRows = this.selectedRows.filter(x => {
          return x.id != id;
        });
      }
      this.load();
    },

    onItineraryItemMangageDialogClosed() {
      this.showItineraryItemManageDialog = false;
      this.load();
    },
    onItineraryItemCopyDialogClosed() {
      this.showItineraryItemCopyDialog = false;
      this.load();
    },
    formatYesNo(value) {
      return value ? 'Yes' : 'No';
    },
    paginateAction(item) {
      if (item == 'next') {
        store.dispatch('itineraryItem/paginate', this.links.next);
      } else if (item == 'previous') {
        store.dispatch('itineraryItem/paginate', this.links.prev);
      }
    },
    deleteSelected() {
      this.itemsToDelete.splice(0, this.itemsToDelete.length);
      this.itemsToDelete = this.selectedRows.map(a => a.id);
      this.showDeleteConfirmDialog = true;
    }
  },
  watch: {
    options: {
      handler() {
        if (this.options.init) {
          this.load();
        } else {
          this.options.init = true;
        }
      },
      deep: true
    },
    subgroup: {
      handler() {
        this.addWeekendButtonEnabled = this.subgroup.homestay_start_date < this.subgroup.homestay_end_date;
      },
      immediate: true
    },
    itinerary_item_template: {
      handler() {
        this.addButtonEnabled = this.itinerary_item_template ? true : false;
      }
    }
  },
  data: () => ({
    headers: [
      { text: 'Date', value: 'date' },
      { text: 'Description', value: 'title' },
      { text: 'Start Time', value: 'start_time' },
      { text: 'End Time', value: 'end_time' },
      { text: 'Time TBA', value: 'time_tba' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ],
    itemsToDelete: [],
    selectedRows: [],
    showItineraryItemManageDialog: false,
    showItineraryItemCopyDialog: false,
    showDeleteConfirmDialog: false,
    itinerary_item_id: null,
    itinerary_item_template: null,
    items_per_page: 100,
    options: {},
    addButtonEnabled: false,
    addWeekendButtonEnabled: false
  })
};
</script>
