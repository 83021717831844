import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Title","error-messages":errors},model:{value:(_vm.itineraryItemLocal.title),callback:function ($$v) {_vm.$set(_vm.itineraryItemLocal, "title", $$v)},expression:"itineraryItemLocal.title"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.itinerary_item_types,"menu-props":{ maxHeight: '400' },"label":"Type","item-text":"title","item-value":"id","error-messages":errors},model:{value:(_vm.itineraryItemLocal.itinerary_item_types_id),callback:function ($$v) {_vm.$set(_vm.itineraryItemLocal, "itinerary_item_types_id", $$v)},expression:"itineraryItemLocal.itinerary_item_types_id"}})}}],null,true)})],1),(_vm.itineraryItemLocal.itinerary_item_types_id == 1)?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Date","rules":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"Date","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.itineraryItemLocal.date),callback:function ($$v) {_vm.$set(_vm.itineraryItemLocal, "date", $$v)},expression:"itineraryItemLocal.date"}})}}],null,true)})],1):_vm._e(),(_vm.itineraryItemLocal.itinerary_item_types_id == 1)?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Start Time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('TimeInput',{attrs:{"disabled":_vm.loading,"label":"Start time","error-messages":errors},model:{value:(_vm.itineraryItemLocal.start_time),callback:function ($$v) {_vm.$set(_vm.itineraryItemLocal, "start_time", $$v)},expression:"itineraryItemLocal.start_time"}})}}],null,true)})],1):_vm._e(),(_vm.itineraryItemLocal.itinerary_item_types_id == 1)?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"End Time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('TimeInput',{attrs:{"disabled":_vm.loading,"label":"End time","error-messages":errors},model:{value:(_vm.itineraryItemLocal.end_time),callback:function ($$v) {_vm.$set(_vm.itineraryItemLocal, "end_time", $$v)},expression:"itineraryItemLocal.end_time"}})}}],null,true)})],1):_vm._e(),(_vm.itineraryItemLocal.itinerary_item_types_id == 1)?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Time TBA"}},[_c(VSwitch,{attrs:{"label":"Time TBA"},model:{value:(_vm.itineraryItemLocal.time_tba),callback:function ($$v) {_vm.$set(_vm.itineraryItemLocal, "time_tba", $$v)},expression:"itineraryItemLocal.time_tba"}})],1)],1):_vm._e(),_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Completed"}},[_c(VTextarea,{attrs:{"label":"Notes","hint":"Printed notes goes here","rows":"5","filled":"","auto-grow":""},model:{value:(_vm.itineraryItemLocal.note),callback:function ($$v) {_vm.$set(_vm.itineraryItemLocal, "note", $$v)},expression:"itineraryItemLocal.note"}})],1)],1)],1),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid || !dirty},on:{"click":_vm.saveAction}},[_vm._v("Save")]),_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading || _vm.itinerary_item_id == 'new',"outlined":"","color":"primary"},on:{"click":_vm.deleteAction}},[_vm._v("Delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }